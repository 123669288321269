.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main{ 
  color: black; 
  padding: 15px; 
  font-size: 16px; 
  display: flex; 
  flex: 1;
  flex-direction: column
}
/* login form */
.form {
  width: 330px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: lightgray;
}
.add-project-form {
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #F2F2F2;
}
.about-card-p {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.about-card {
  padding: 10px;
  background: lightblue;
  flex-grow: 1;
}
.separater {
  height: 10px;
}


/* Projects Page */
.titlebar{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.projects-titlebar {
  display: flex;
  margin-top: 10px;
  align-items: center;
  padding:5px;
}
.projects-titlebar-photo {
  font-size: 15px;
  color: gray;
  margin-right: 20px;
}
.projects-titlebar-title {
  flex: 1.5;
  font-size: 15px;
  color: gray;
}
.projects-titlebar-cat {
  flex: 1;
  font-size: 15px;
  color: gray;
}
.projects-titlebar-btns {
  display: flex;
  flex: 1;
  font-size: 15px;
  color: gray;
}
.projects-itembar {
  display: flex;
  margin-top: 0px;
  align-items: center;
  padding:10px;
}
.projects-itembar-colored {
  display: flex;
  align-items: center;
  background: #F2F2F2;
  border-radius: 5px;
  padding:10px;
}
.projects-item-photo {
  font-size: 13px;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}
.projects-item-title {
  flex: 1.5;
  font-size: 14px;
}
.projects-item-cat {
  flex: 1;
  font-size: 13px;
}
.projects-item-btns {
  display: flex;
  flex: 1;
  font-size: 13px;
}
.btn-view{
  margin-right: 5px;
  flex:1;
}
.btn-edit{
  flex:1;
  width:70px
}
.btn-submit{
  max-width: 300px;
  align-self: center;
}